import axios from "axios";

let wsStudioHeader =
  "https://app-hsxstudio-staging.azurewebsites.net/WebService.asmx/";

let apiStudio = axios.create({
  baseURL: wsStudioHeader,
  headers: {
    Accept: "application/json",
    "content-type": "application/json",
  },
});

export function UserLogin(callback, userObj) {
  apiStudio.post("UserLogin", userObj).then(
    (response) => {
      // console.log("UserLogin: == ", JSON.stringify(response.data));
      if (callback) {
        callback(response.data.d);
      }
    },
    (error) => {
      console.log("UserLogin fail: " + error);
    }
  );
}

export function ForgotPassword(callback, userObj) {
  apiStudio.post("ForgotPassword", userObj).then(
    (response) => {
      // console.log("ForgotPassword: == ", JSON.stringify(response.data));
      if (callback) {
        callback(response.data.d);
      }
    },
    (error) => {
      console.log("ForgotPassword fail: " + error);
    }
  );
}

export function UpdatePassword(callback, userObj) {
  apiStudio.post("UpdatePassword", userObj).then(
    (response) => {
      // console.log("UpdatePassword: == ", JSON.stringify(response.data));
      if (callback) {
        callback(response.data.d);
      }
    },
    (error) => {
      console.log("UpdatePassword fail: " + error);
    }
  );
}

export function RegisterUser(callback, userObj) {
  apiStudio.post("RegisterUser", JSON.stringify({ userdata: userObj })).then(
    (response) => {
      // console.log("RegisterUser: == ", JSON.stringify(response.data));
      if (callback) {
        callback(response.data.d);
      }
    },
    (error) => {
      console.log("RegisterUser fail: " + error);
    }
  );
}

export function VerifyEmail(callback, userObj) {
  apiStudio.post("VerifyEmail", userObj).then(
    (response) => {
      // console.log("VerifyEmail: == ", JSON.stringify(response.data));
      if (callback) {
        callback(response.data.d);
      }
    },
    (error) => {
      console.log("VerifyEmail fail: " + error);
    }
  );
}

export function MarketList(callback) {
  apiStudio.get("MarketList", { data: null }).then(
    (response) => {
      // console.log("MarketList: == ", JSON.stringify(response.data));
      if (callback) {
        callback(response.data.d);
      }
    },
    (error) => {
      console.log("MarketList fail: " + error);
    }
  );
}
